var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _vm.isOld
            ? _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm revisionLayout",
                  attrs: { title: "개정", topClass: "topcolor-orange" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-1" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: _vm.editable && _vm.rev.isRevision,
                            editable: _vm.editable && _vm.rev.isRevision,
                            label: "개정번호",
                            name: "revisionNum",
                          },
                          model: {
                            value: _vm.sop.revisionNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.sop, "revisionNum", $$v)
                            },
                            expression: "sop.revisionNum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "개정일시",
                            name: "regDtStr",
                          },
                          model: {
                            value: _vm.sop.regDtStr,
                            callback: function ($$v) {
                              _vm.$set(_vm.sop, "regDtStr", $$v)
                            },
                            expression: "sop.regDtStr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-1" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "개정자",
                            name: "regUserName",
                          },
                          model: {
                            value: _vm.sop.regUserName,
                            callback: function ($$v) {
                              _vm.$set(_vm.sop, "regUserName", $$v)
                            },
                            expression: "sop.regUserName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4" },
                      [
                        _c("c-moc", {
                          attrs: {
                            editable: _vm.editable && _vm.rev.isRevision,
                            isSubmit: _vm.saveCallData,
                            document: _vm.sop,
                            documentId: _vm.popupParam.mdmSopId,
                            documentTitle: "diagramTitle",
                            label: "MOC번호",
                            name: "sopMocId",
                          },
                          on: {
                            "update:document": function ($event) {
                              _vm.sop = $event
                            },
                          },
                          model: {
                            value: _vm.sop.sopMocId,
                            callback: function ($$v) {
                              _vm.$set(_vm.sop, "sopMocId", $$v)
                            },
                            expression: "sop.sopMocId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: _vm.editable && _vm.rev.isRevision,
                            editable: _vm.editable && _vm.rev.isRevision,
                            label: "개정사유",
                            name: "revisionContent",
                          },
                          model: {
                            value: _vm.sop.revisionContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.sop, "revisionContent", $$v)
                            },
                            expression: "sop.revisionContent",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "SOP 정보" },
            },
            [
              _c(
                "template",
                { slot: "card-select" },
                [
                  _vm.isOld
                    ? _c("c-select", {
                        attrs: {
                          editable: _vm.editable && !_vm.rev.isRevision,
                          comboItems: _vm.sop.revs,
                          type: "custom",
                          typetext: "개정이력",
                          itemText: "revisionNum",
                          itemValue: "mdmSopId",
                          label: "",
                          name: "revSelectValue",
                        },
                        on: { input: _vm.getRevVersion },
                        model: {
                          value: _vm.sop.revSelectValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.sop, "revSelectValue", $$v)
                          },
                          expression: "sop.revSelectValue",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.isOld && !_vm.rev.isRevision
                        ? _c("c-qr-btn", {
                            attrs: {
                              mobileUrl:
                                "/mdm/pim/sopManage?mdmSopId=" +
                                _vm.popupParam.mdmSopId,
                            },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && _vm.isOld && !_vm.rev.isRevision,
                            expression: "editable && isOld && !rev.isRevision",
                          },
                        ],
                        attrs: { label: "개정", icon: "restart_alt" },
                        on: { btnClicked: _vm.setRevision },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && _vm.isOld && _vm.rev.isRevision,
                            expression: "editable && isOld && rev.isRevision",
                          },
                        ],
                        attrs: { label: "개정취소", icon: "restart_alt" },
                        on: { btnClicked: _vm.cancelRevision },
                      }),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.sop,
                              mappingType: _vm.mappingType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveSopInfo,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "안전작업표준 작업명",
                        name: "sopName",
                      },
                      model: {
                        value: _vm.sop.sopName,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "sopName", $$v)
                        },
                        expression: "sop.sopName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "안전작업표준 관리번호",
                        name: "sopNo",
                      },
                      model: {
                        value: _vm.sop.sopNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "sopNo", $$v)
                        },
                        expression: "sop.sopNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-process", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "단위공정",
                        name: "subProcessCd",
                      },
                      model: {
                        value: _vm.sop.subProcessCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "subProcessCd", $$v)
                        },
                        expression: "sop.subProcessCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-select", {
                      attrs: {
                        codeGroupCd: "WORK_CLASS_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "workClassCd",
                        label: "작업구분",
                      },
                      model: {
                        value: _vm.sop.workClassCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "workClassCd", $$v)
                        },
                        expression: "sop.workClassCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                  [
                    _c("c-dept-multi", {
                      attrs: {
                        label: "관리부서",
                        parentCheckDepts: _vm.sop.managementDepts,
                        plantCd: _vm.sop.plantCd,
                        name: "managementDepts",
                      },
                      model: {
                        value: _vm.sop.managementDepts,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "managementDepts", $$v)
                        },
                        expression: "sop.managementDepts",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-select", {
                      attrs: {
                        codeGroupCd: "WORK_TYPE_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "workTypeCd",
                        label: "작업유형",
                      },
                      model: {
                        value: _vm.sop.workTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "workTypeCd", $$v)
                        },
                        expression: "sop.workTypeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-select", {
                      attrs: {
                        codeGroupCd: "WORK_KIND_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "workKindCd",
                        label: "작업형태",
                      },
                      model: {
                        value: _vm.sop.workKindCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "workKindCd", $$v)
                        },
                        expression: "sop.workKindCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.sop.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "plantCd", $$v)
                        },
                        expression: "sop.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        isFlag: true,
                        label: "사용여부",
                        name: "useFlag",
                      },
                      model: {
                        value: _vm.sop.useFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.sop, "useFlag", $$v)
                        },
                        expression: "sop.useFlag",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticStyle: { height: "0" } },
            [
              _vm.rev.isRevision
                ? _c(
                    "q-banner",
                    {
                      staticClass:
                        "text-white bg-red sop-step-table-info-banner",
                      attrs: { "inline-actions": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "avatar",
                            fn: function () {
                              return [
                                _c("q-icon", {
                                  attrs: {
                                    name: "info_outline",
                                    color: "white",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4205562712
                      ),
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$language(
                              "개정시에 `작업단계 > 유해위험요인 > 원인` 정보는 이전 버전 정보를 사용하게 됩니다."
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isOld
            ? _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "작업절차",
                    gridHeight: _vm.setSopStepheight,
                    merge: _vm.grid.merge,
                    columns: _vm.grid.columns,
                    data: _vm.sop.sopStepHazards,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    disabled: _vm.rev.isRevision,
                    editable: _vm.editable && !_vm.rev.isRevision,
                    isExcelDown: false,
                    isFullScreen: false,
                    selection: "multiple",
                    rowKey: "jobStepId",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            col.name === "sopStep"
                              ? [
                                  _c(
                                    "q-item",
                                    { staticClass: "card-sop-step-list" },
                                    [
                                      _c(
                                        "q-item-section",
                                        { attrs: { thumbnail: "" } },
                                        [
                                          _c("img", {
                                            class: {
                                              "cursor-pointer": Boolean(
                                                props.row.sopStepSysAttachFileId
                                              ),
                                            },
                                            staticStyle: {
                                              height: "80px",
                                              width: "80px",
                                            },
                                            attrs: {
                                              src: props.row["sopStepDownPath"],
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.setPreview(
                                                  props.row
                                                    .sopStepSysAttachFileId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "q-item-section",
                                        [
                                          _c("q-chip", {
                                            staticStyle: {
                                              "max-width": "60px",
                                            },
                                            attrs: {
                                              dense: "",
                                              outline: "",
                                              square: "",
                                              color: "teal",
                                              "text-color": "white",
                                              label: `# ${props.row.jobStepNo}`,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.openSopStepDetail(
                                                  props.row.jobStepId
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "q-item-label",
                                            {
                                              staticClass: "sopStep-card-title",
                                            },
                                            [
                                              _c("q-btn", {
                                                staticClass: "tableinnerBtn",
                                                attrs: {
                                                  flat: "",
                                                  align: col.align,
                                                  color: "blue-6",
                                                  label:
                                                    props.row["jobStepName"],
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openSopStepDetail(
                                                      props.row.jobStepId
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.row["jobStepDesc"]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : col.name === "hazard"
                              ? [
                                  props.row.sopStepHazardId
                                    ? _c(
                                        "q-item",
                                        { staticClass: "card-sop-step-list" },
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  staticClass:
                                                    "sopStep-card-title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `${props.row["ramRiskHazardClassName"]} / ${props.row["riskHazardName"]}`
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2084063732
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.rev.isRevision
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addSopStep },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.rev.isRevision
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.deleteSopStep },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.preview && _vm.preview.src
        ? _c(
            "q-dialog",
            {
              model: {
                value: _vm.preview.isShow,
                callback: function ($$v) {
                  _vm.$set(_vm.preview, "isShow", $$v)
                },
                expression: "preview.isShow",
              },
            },
            [
              _c(
                "q-card",
                { staticClass: "preview-image-card" },
                [
                  _c("q-img", { attrs: { src: _vm.preview.src } }),
                  _c(
                    "q-card-section",
                    [
                      _c("q-btn", {
                        staticClass: "absolute",
                        staticStyle: {
                          top: "0",
                          right: "12px",
                          transform: "translateY(-50%)",
                        },
                        attrs: { fab: "", color: "primary", icon: "save_alt" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fileDown(_vm.preview)
                          },
                        },
                      }),
                      _c("div", { staticClass: "row no-wrap items-center" }, [
                        _c("div", { staticClass: "col text-h6" }, [
                          _vm._v(" " + _vm._s(_vm.preview.oriFileNm) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-auto text-grey text-caption q-pt-md row no-wrap items-center",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$comm.bytesToSize(_vm.preview.fileSize)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }